@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

body {
    font-family: 'Rubik', sans-serif;
    background-color: #343a40;
    color: white;
    font-size: 24px;
}

// .body-container {
//     width: 100%;
//     // height: 100vh;
//     height: 400px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: white;
// }

.main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 80px 0;
}

.image {
    width: 85%;
    display: block;
    margin-bottom: 50px;
    @media only screen and (min-width: 768px) {
        width: 100%;
        max-width: 500px;
    }
}

.text {
    text-align: center;
    line-height: 1.5;
}

a {
    color: white;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}
